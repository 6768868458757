// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import styles from './Categories.module.scss';

const Categories = ({categories}) => (
  <div className={styles['categories']}>
    <ul className={styles['categories__list']}>
      {categories.map((category) => (
        <li className={styles['categories__list-item']} key={category.fieldValue}>
          <Link to={`/category/${kebabCase(category.fieldValue)}/`} className={styles['categories__list-item-link']}>
            {category.fieldValue} ({category.totalCount})
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default Categories;
